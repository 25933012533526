.pb-5, .py-5 {
    padding-bottom: 3rem !important;
}

.pt-5, .py-5 {
    padding-top: 3rem !important;
}
@media (min-width: 1200px){
.col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}}
.testimonialBox {
    background-color: #ffffff;
    padding: 30px;
    margin-bottom: 30px;
    -webkit-box-shadow: -1px -1px 31px -5px rgba(184,184,184,0.49);
    -moz-box-shadow: -1px -1px 31px -5px rgba(184,184,184,0.49);
    box-shadow: -1px -1px 31px -5px rgba(184,184,184,0.49);
    overflow: auto;
    height: 430px;
}
.text-center {
    text-align: center !important;
}
.testimonialBox h2 {
    font-size: 21px;
    padding-top: 18px;
}
h1, h2, h3, h4, h5, h6 {
    margin: 0;
    letter-spacing: 1px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
}
.testimonialBox > p {
    color: #333;
    font-size: 16px;
    margin: 30px 0;
    position: relative;
    z-index: 1;
}
p {
    font-size: 14px;
    color: #000;
    line-height: 1.9em;
    letter-spacing: 1px;
}
.pr-2, .px-2 {
    padding-right: 0.5rem !important;
}
.float-left {
    float: left !important;
}
.lureadmore a {
    background: #083f62;
    color: #fff;
    padding: 4px 11px;
    border-radius: 6px;
    font-size: 15px;
}
.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.testimonialUser {
    margin-top: 0px;
}
.testimonialUser span {
    font-size: 14px;
    float: right;
}
b, strong {
    font-weight: bolder;
}
.icon-bar {
    position: fixed;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 99999;
}
.d-sm-block {
    display: block !important;
}
.icon-bar a:hover{
    /* background-color: #585252; */
    font-size: 22px;
    background-color: #212529;
}
.icon-bar a {
    display: block;
    text-align: center;
    padding: 16px;
    transition: all 0.3s ease;
    color: white;
    font-size: 20px;
}
.facebook1 {
    background: #3B5998;
    color: white;
}
.twitter1 {
    background: #55ACEE;
    color: white;
}
.linkedin {
    background: #007bb5;
    color: white;
}
.youtube {
    background: #bb0000;
    color: white;
}
.whatsapp {
    background: green;
    color: white;
}
.right-button {
    position: fixed;
    bottom: 0px;
    right: 0px;
    top: 50%;
    z-index: 999;
    height: 119px;
}
.right-button a:hover{
    opacity: 0.8;
}
@media (min-width: 768px){
.py-md-5 {
    padding-bottom: 3rem !important;
}
.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%; 
    max-width: 100%;
}
h3.title-w3pvt {
    text-shadow: 3px 1px 3px rgb(45 45 45 / 14%);
    font-size: 2.2em;
    color: #000;
    font-weight: 700;
    text-transform: uppercase;
}
.text-center {
    text-align: center !important;
}
.mb-5, .my-5 {
margin-bottom: 3rem !important;
}
.mt-3 {
    margin-top: 1rem !important;
}
.order-lg-2 {
    -webkit-box-ordinal-group: 3;
    /* -ms-flex-order: 2;
    order: 2; */
}
.col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}
.order-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
}
.col-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}
.position-relative {
    position: relative !important;
}
.thumb img, .thumbnail img, .india-tv a img {
    border-radius: 6px;
}
.w-100 {
    width: 100% !important;
}
img {
    vertical-align: middle;
    border-style: none;
}
img {
    overflow-clip-margin: content-box;
    overflow: clip;
}
.thumb .video-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border: 0 none;
}
a.video-popup {
    display: block;
    position: relative;
    margin: 0 auto;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: #fff;
    -webkit-transition: all 1s cubic-bezier(0, 0, 0.2, 1);
    -o-transition: all 1s cubic-bezier(0, 0, 0.2, 1);
    transition: all 1s cubic-bezier(0, 0, 0.2, 1);
    -webkit-box-shadow: 0 20px 30px rgb(0 0 0 / 20%);
    box-shadow: 0 20px 30px rgb(0 0 0 / 20%);
}
.play_icon{
    font-size: 40px;
    margin-top: 15px;
    margin-left: 5px;
}
iframe {
    width: 100%;
    height: 435px;
    border-top-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
}
.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    outline: 0;
}
.fade {
    opacity: 0;
    transition: opacity 0.15s linear;
}
.modal.fade .modal-dialog {
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    transform: translate(0, -25%);
}
.modal-lg {
    max-width: 800px;
}
.modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
}
.modal-dialog {
    margin: 1.75rem auto;
}
.modal-dialog-centered {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}
.modal-dialog {
    position: relative;
    width: auto;
    pointer-events: none;
}
.modal-content {
    position: relative;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
}
.modal-body {
    position: relative;
    padding: 0px;
}
.modal-body {
    -webkit-box-flex: 1;
    flex: 1 1 auto;
}
.video-gary-popup .modal-content .close {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #fff;
    line-height: 32px;
    text-align: center;
    color: #000;
    font-size: 16px;
    opacity: 1;
    position: absolute;
    right: -13px;
    top: -13px;
}
.order-lg-2 {
    -webkit-box-ordinal-group: 3;
}
.col-lg-6 {
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
}
.mt-3, .my-3 {
    margin-top: 1rem !important;
}
.row {
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}
.img-fluid {
    max-width: 100%;
    height: auto;
}
img {
    vertical-align: middle;
    border-style: none;
}
.date-span {
    font-size: 14px;
    font-weight: 300;
    margin-top: 10px;
}
.mt-2 {
    margin-top: 0.5rem !important;
}
.mt-3 {
    margin-top: 1rem !important;
}
.india-tv-cantt h3 {
    font-size: 18px;
    letter-spacing: 1px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    line-height: 1.5em;
}
.readmore-button a {
    font-size: 14px;
    color: #fff;
    background: #1a8ed4;
    padding: 5px 15px;
}
.readmore-button a:hover {
    opacity: 0.6;
    color: #fff;
    text-decoration: none;
    }}


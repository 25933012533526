.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
h3.title-w3pvt, h3.title-w3pvt.two {
    text-shadow: 3px 1px 3px rgb(45 45 45 / 14%);
    font-size: 2.2em;
    color: #000;
    font-weight: 700;
    text-transform: uppercase;
}
.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}
.text-center {
    text-align: center !important;
}
.mb-5, .my-5 {
    margin-bottom: 3rem !important;
}
.fadeInUp {
    animation-name: fadeInUp;
}
.mt-3, .my-3 {
    margin-top: 1rem !important;
}
.row {
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.blog-item {
    background: #fff;
    border: #ddd solid 1px;
    overflow: hidden;
    height: 100%;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 30%);
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.rs-blog .blog-item .blog-img {
    position: relative;
    overflow: hidden;
}body a {
    text-decoration: none;
    transition: 0.5s all;
    -webkit-transition: 0.5s all;
    -moz-transition: 0.5s all;
    -o-transition: 0.5s all;
    -ms-transition: 0.5s all;
}
.rs-blog .blog-item .blog-img img {
    width: 100%;
    border-radius: 2px 2px 0 0;
}
.img-fluid {
    max-width: 100%;
    height: auto;
}
img {
    vertical-align: middle;
    border-style: none;
}
.date {
    position: absolute;
    z-index: 99999;
    bottom: -20px;
    color: #000;
    background: #fff;
    font-size: 14px;
    margin: 10px;
    width: 155px;
    padding: 7px 10px;
}
.blog-item h4 a {
    font-size: 20px;
    color: #000!important;
    font-weight: 700;
    line-height: 1.5;
    text-align: left;
}
.content-wrapper {
    padding: 20px 8px;
}
.content-wrapper a:hover {
   opacity: 0.6;
   color: #fff;
   text-decoration: none;
}
.blog-desc {
    height: 100%;
    width: 100%;
    position: relative;
    min-height: 80px;
}
.blog-btn {
    background: #1a8ed4;
    padding: 5px 16px;
    font-size: 14px;
    color: #fff;
    display: block;
    margin-top: 10px;
    text-decoration: none !important;
}
.blog-btn2 {
    position: relative;
    top: 20px;
    width: 100px;
}
.mb-5 {
    margin-bottom: 3rem !important;
}



.bg11 {
    background-image: url(https://gkkediaandco.com/wp-content/themes/gkkedia/images/bg11.jpg);
    background-size: cover;
    background-position: center center;
}
@media (min-width: 1200px){
.container {
    max-width: 1140px;
}}
.pt-100 {
    padding: 70px 0px!important;
}
.bg-gr {
    background: #fff;
    padding: 20px;
    border: none;
}
@media (min-width: 768px){
.col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}}
.bg-gr .nav-tabs {
    border-bottom: none!important;
}
.nav-tabs {
    border-bottom: 1px solid #dee2e6;
}
.nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

ul {
    margin: 0;
    padding: 0;
}
li {
    display: list-item;
    text-align: -webkit-match-parent;
}
.nav-tab-contact li a.active {
    background: #000;
    color: #fff;
}
.nav-tab-contact li a {
    color: #fff!important;
}
.nav-tab-contact li a {
    background: #65add8;
    border: none;
    margin-right: 2px;
    color: #fff!important;
    cursor: default;
    padding: 5px 51px;
    line-height: 40px;
    cursor: pointer;
}
li > a:only-child:after {
    content: '';
}
.tab-content > .active {
    display: block;
}
.contact-sidebar-section .contact-form .form-field {
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
}
.contact-sidebar-section .contact-form .form-field input {
    width: 100%;
    height: 45px;
    line-height: 45px;
    padding: 0 20px;
    color: #666666;
    border: 1px solid #ddd;
    border-radius: 3px;
}
.contcat-form .form-field select {
    height: 45px!important;
}
.contact-address-section .contact-info {
    padding: 20px 0px 0px 0px;
    border-radius: 5px;
    background: #ffffff;
}
.contact-address-section .contact-info p {
    color: #000;
    margin: 10px;
    padding-bottom: 14px;
    display: flex;
    font-size: 14px;
    line-height: 1.2em;
    letter-spacing: 1px;
}
p { 
    font-size: 14px;
    color: #000;
    line-height: 1.9em;
    letter-spacing: 10px;
}
.contact-address-section .contact-info i {
    color: #1a8ed4;
    font-size: 26px;
    margin: 0;
    padding: 0;
    display: flex;
    padding-right: 15px;
}
.contact-address-section .contact-info h4 {
    color: #000333;
    margin: 0;
    padding: 15px 0;
}

.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.fa-home:before {
    content: "\f015";
}
.fa-phone:before {
    content: "\f095";
}
.fa-mobile:before {
    content: "\f10b";
}
.fa-envelope:before {
    content: "\f0e0";
}
.map-contact {
    padding-top: 45px;
}
@media (min-width: 768px){
.col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}}
@media (min-width: 768px){
.pb-md-5, .py-md-5 {
    padding-bottom: 3rem !important;
}}
@media (min-width: 1200px){
.container {
    max-width: 1140px;
}}
.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}
.sidebar-inner {
    background: #faf8f7;
    border-radius: 0 5px 5px 0;
}
.row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
@media (min-width: 992px){
.col-lg-4 {
    -webkit-box-flex: 0;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;

}}
.sider-contact {
    background: #65add8;
    border-radius: 5px 0 0 5px;
    padding: 61px 40px 60px;
    color: #ffffff;
}
.cont {
    font-size: 22px;
    color: #fff;
    font-weight: 700;
    text-align: center;
}
p {
    font-size: 14px;
    color: #000;
    line-height: 1.9em;
    letter-spacing: 1px;
}
.sider-contact .sidebar-text {
    color: #ffffff;
    margin: 30px 0 26px;
}
.white-color {
    color: #ffffff !important;
    font-size: 20px;
    padding: 10px 0px;
}
h1, h2, h3, h4, h5, h6 {
    margin: 0;
    letter-spacing: 1px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
}
.sidebar-opening-hour ul {
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding-left: 10px; */
}
ul {
    margin: 0;
    padding: 0;
}
.sidebar-opening-hour li {
    margin-left: 20px;
    list-style: square;
}
@media (min-width: 992px){
.col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}}
@media (min-width: 768px){
.col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}}
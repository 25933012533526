.btn {
    margin-top: 20px;
    border: 2px solid #fff;
    border-radius: 0;
    text-transform: uppercase;
    font-size: .75rem;
    font-weight: 500;
    color: #fff;
    padding: 0.5rem 1.6rem;
    position: relative;
    background: 0 0;
}
.btn-dark {
    background: #333 !important;
    border-color: #080909;
    color: #fff!important;
    padding: 1.1rem 1.4rem!important;
    margin-left: 5px;
}
.text-center {
    text-align: center !important;
}
@media (min-width: 768px){
.col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}}
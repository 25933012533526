.pb-5, .py-5 {
    padding-bottom: 3rem !important;
}
.pt-5, .py-5 {
    padding-top: 3rem !important;
}
@media (min-width: 1200px){
.container {
    max-width: 1140px;
}}
.row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
@media (min-width: 768px){
.col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}}
.main-timeline {
    font-family: 'Roboto', sans-serif;
}
.main-timeline:before {
    content: '';
    height: 540%;
    width: 2px;
    border: 2px dashed #000;
    transform: translateX(-50%);
    position: absolute;
    left: 50%;
    top: 330px;
}
.main-timeline .timeline {
    width: 50%;
    padding: 100px 70px 0 25px;
    margin: 0 50px 0 0;
    float: left;
    position: relative;
}
.main-timeline .timeline-content {
    padding: 15px 15px 15px 40px;
    border: 2px solid #0c7dc3;
    border-radius: 15px 0 15px 15px;
    display: block;
    position: relative;
    text-decoration: none;
}
.timeline a {
    color: #333;
}
.main-timeline .timeline-year {
    color: #fff;
    background-color: #0c7dc3;
    font-size: 15px;
    font-weight: 900;
    text-align: center;
    line-height: 98px;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    position: absolute;
    right: -120px;
    top: -85px;
}
.main-timeline .timeline-year:after {
    content: '';
    height: 130px;
    width: 130px;
    border: 8px solid #0c7dc3;
    border-left-color: transparent;
    border-radius: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(-20deg);
    position: absolute;
    left: 50%;
    top: 50%;
}
.main-timeline .title {
    color: #222;
    font-size: 20px;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 0 0 7px 0;
}
h1, h2, h3, h4, h5, h6 {
    margin: 0;
    letter-spacing: 1px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
}
.main-timeline .description {
    color: #222;
    font-size: 15px;
    letter-spacing: 1px;
    text-align: justify;
    margin: 0 0 5px;
}
p {
    font-size: 14px;
    color: #000;
    line-height: 1.9em;
    letter-spacing: 1px;
}
ul {
    margin: 0;
    padding: 0;
}
li {
    display: list-item;
    text-align: -webkit-match-parent;
}
::marker {
    unicode-bidi: isolate;
    font-variant-numeric: tabular-nums;
    text-transform: none;
    text-indent: 0px !important;
    text-align: start !important;
    text-align-last: start !important;
}
.main-timeline .timeline:nth-child(even) {
    padding: 100px 25px 0 70px;
    margin: 0 0 0 50px;
    float: right;
}
.main-timeline .timeline {
    width: 50%;
    padding: 100px 70px 0 25px;
    margin: 0 50px 0 0;
    float: left;
    position: relative;
}
.timeline {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
}
.main-timeline .timeline:nth-child(even) .timeline-content {
    padding: 15px 15px 15px 40px;
    border-radius: 0 15px 15px 15px;
}
.timeline:nth-child(4n+2) .timeline-content, .timeline:nth-child(4n+2) .timeline-year:after {
    border-color: #ed2024;
}
.main-timeline .timeline-content {
    padding: 15px 15px 15px 40px;
    border: 2px solid #0c7dc3;
    border-radius: 15px 0 15px 15px;
    display: block;
    position: relative;
}
.timeline a {
    color: #333;
}
.main-timeline .timeline:nth-child(even) .timeline-year {
    right: auto;
    left: -120px;
}
.timeline:nth-child(4n+2) .timeline-content:after, .timeline:nth-child(4n+2) .timeline-icon, .timeline:nth-child(4n+2) .timeline-year {
    background-color: #ef4b4e;
}
.main-timeline .timeline-year {
    color: #fff;
    background-color: #0c7dc3;
    font-size: 15px;
    font-weight: 900;
    text-align: center;
    line-height: 98px;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    position: absolute;
    right: -120px;
    top: -85px;
}

.timeline:nth-child(4n+3) .timeline-content, .timeline:nth-child(4n+3) .timeline-year:after {
    border-color: #63ad4b;
}
.timeline:nth-child(4n+3) .timeline-year {
    background-color: #63ad4b;
}
.timeline:nth-child(4n+5) .timeline-content, .timeline:nth-child(4n+5) .timeline-year:after {
    border-color: #ef4b4e;
}
.timeline:nth-child(4n+5) .timeline-content:after, .timeline:nth-child(4n+5) .timeline-icon, .timeline:nth-child(4n+5) .timeline-year {
    background-color: #ef4b4e;
}
.sec-color {
    background-color: #faf8f7 !important;
    /* padding-top: 48px; */
}
@media screen and (max-width: 600px){
.rs-breadcrumbs .breadcrumbs-image {
    min-height: 180px;
}}
.rs-breadcrumbs .breadcrumbs-image {
    position: relative;
    overflow: hidden;
}
.rs-breadcrumbs .breadcrumbs-image:before {
    position: absolute;
    content: "";
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 50%;
    height: 150px;
}
.img-fluid {
    max-width: 100%;
    height: auto;
}
.rs-breadcrumbs .breadcrumbs-image .breadcrumbs-inner {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}
@media (min-width: 1200px){
.container {
    max-width: 1140px;
}}
.fadeInUp {
    animation-name: fadeInUp;
}
.rs-breadcrumbs .breadcrumbs-image .breadcrumbs-inner .breadcrumbs-text .breadcrumbs-title {
    width: 100%;
    font-size: 42px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin: 0 0 10px;
    color: #ffffff;
    float: left;
    text-align: left;
    line-height: 42px;
}
ul {
    margin: 0;
    padding: 0;
    text-align: left;
}
.rs-breadcrumbs .breadcrumbs-image .breadcrumbs-inner .breadcrumbs-text .breadcrumbs-subtitle li {
    color: #ffffff;
    display: inline-block;
    font-size: 15px;
    font-weight: 500;
}
.rs-breadcrumbs .breadcrumbs-image .breadcrumbs-inner .breadcrumbs-text .breadcrumbs-subtitle li a {
    color: #ffffff;
    position: relative;
    padding-right: 24px;
   
}
.rs-breadcrumbs .breadcrumbs-image .breadcrumbs-inner .breadcrumbs-text .breadcrumbs-subtitle li a:before, .rs-breadcrumbs .breadcrumbs-image .breadcrumbs-inner .breadcrumbs-text .breadcrumbs-subtitle li a:after {
    background-color: #ffffff;
    content: "";
    height: 15px;
    width: 1px;
    position: absolute;
    top: 5px;
    transform: rotate(20deg);
    right: 7px;
}
.rs-breadcrumbs .breadcrumbs-image .breadcrumbs-inner .breadcrumbs-text .breadcrumbs-subtitle li a:after {
    background-color: #ffffff;
    content: "";
    height: 15px;
    width: 1px;
    position: absolute;
    top: 5px;
    transform: rotate(20deg);
    right: 7px;
}
.rs-breadcrumbs .breadcrumbs-image .breadcrumbs-inner .breadcrumbs-text .breadcrumbs-subtitle li {
    color: #ffffff;
    display: inline-block;
    font-size: 15px;
    font-weight: 500;
}
@media screen and (max-width: 600px){
.breadcrumbs-image img {
    min-height: 180px!important;
}}
.img-fluid {
    max-width: 100%;
    height: auto;
}
img {
    vertical-align: middle;
    border-style: none;
}
*, *::before, *::after {
    box-sizing: border-box;
}
img {
    overflow-clip-margin: content-box;
    overflow: clip;
}
@media screen and (max-width: 600px){
.rs-breadcrumbs .breadcrumbs-image .breadcrumbs-inner .breadcrumbs-text .breadcrumbs-title {
    font-size: 30px;
}}
.rs-breadcrumbs .breadcrumbs-image .breadcrumbs-inner .breadcrumbs-text .breadcrumbs-title {
    width: 100%;
    font-size: 42px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin: 0 0 10px;
    color: #ffffff;
    float: left;
    line-height: 42px;
}
@media screen and (max-width: 480px){
.breadcrumbs-title {
    font-size: 17px!important;
    line-height: 20px!important;
}}
.service-scroll {
    position: relative;
    height: 240px;
}
.service-call ul li {
    width: 100%;
    margin-right: 12px;
    list-style: none;
    /* background: url(../images/widget-link.png) 0% 10px no-repeat; */
    padding-left: 30px;
    /* line-height: 31px; */
    /* color: #000; */
    font-size: 14px;
    color: #000;
    line-height: 1.9em;
    letter-spacing: 1px;
}
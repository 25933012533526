.pt-5, .py-5 {
    padding-top: 3rem!important;
}
.row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
@media (min-width: 992px){
.col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}}
@media (min-width: 768px){
.col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}}
.contact-sidebar-section .contact-form-one {
    padding: 50px 50px 50px 20px;
}
.contact-form-one {
    /* border-top: 1px solid #ddd; */
    padding: 0px 40px!important;
}
.contact-form-one h3 {
    font-size: 20px;
    padding: 20px 0px;
}
form {
    display: block;
    margin-top: 0em;
}
@media (min-width: 768px){
.col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}}
@media (min-width: 576px){
.col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}}
.contact-sidebar-section .contact-form .form-field {
    margin-bottom: 20px;
    position: relative;
}
.contact-sidebar-section .contact-form .form-field input {
    width: 100%;
    height: 45px;
    line-height: 45px;
    padding: 0 20px;
    color: #666666;
    border: 1px solid #ddd;
    border-radius: 3px;
}
.contact-sidebar-section .contact-form .form-field textarea {
    width: 100%;
    padding: 20px;
    color: #666666;
    border: 1px solid #ddd;
    border-radius: 5px;
    resize: none;
    height: 100px;
}
.fileContainer [type=file] {
    cursor: inherit;
    display: block;
    font-size: 999px;
    filter: alpha(opacity=0);
    min-height: 100%;
    min-width: 100%;
    opacity: 0;
    position: absolute;
    right: 0;
    text-align: right;
    top: 0;
}
.fileContainer {
    background: #fff;
    float: left;
    overflow: hidden;
    position: relative;
    width: 100%;
    line-height: 40px;
    padding: 0 20px;
    color: #666666;
    border: 1px solid #ddd;
    border-radius: 3px;
    font-weight: 400;
}
.form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
label {
    display: inline-block;
    margin-bottom: 0.5rem;
}
@media (min-width: 768px){
.pb-md-5, .py-md-5 {
    padding-bottom: 3rem!important;
}}
@media (min-width: 768px){
.pt-md-5, .py-md-5 {
    padding-top: 3rem!important;
}}
@media (min-width: 1200px){
.container {
    max-width: 1140px;
}}
.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}
.sidebar-inner {
    /* background: #faf8f7; */
    border-radius: 0 5px 5px 0;
}
.readon {
    background: #65add8;
    line-height: normal;
    color: #ffffff;
    transition: all 0.3s ease 0s;
    border-radius: 3px;
    padding: 0 20px;
    height: 50px;
    line-height: 50px;
    position: relative;
    text-transform: uppercase;
    font-weight: 400;
    display: inline-block;
    border-color: #65add8;
}
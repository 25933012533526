.fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
  }
  .service-call h2 {
    font-size: 18px;
    padding-bottom: 12px;
    letter-spacing: 1px;
    font-weight: 800;
    font-family: 'Poppins', sans-serif;
    text-align:left;
    margin-left: 15px;
}
  .rs-left-border-title {
    padding-left: 0px;
    position: relative;
    margin-right: 10px;
  }
  .rs-left-border-title:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 30px;
    background: #0e78b6;
    margin-left: 15px;
}
  .rs-left-border-title h1 {
    text-shadow: 3px 1px 3px rgb(45 45 45 / 14%);
    font-size: 22px!important;
    color: #000;
    font-weight: 700;
    text-transform: uppercase;
    padding-bottom: 2px!important;
  }
  .readmorebtn {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    color: #212529;
  }
  .readmorebtn:hover{
    color:#6c5d5d;
  }
.borders {
    border: 1px solid #ddd;
    margin-bottom: 20px;
    padding: 20px;
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.3);
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    background: #fff;
}
@media (min-width: 992px){
.pl-lg-0, .px-lg-0 {
    padding-left: 0 !important;
}
}
@media (min-width: 992px){
.pr-lg-0, .px-lg-0 {
    padding-right: 0 !important;
}}
.p-lg-5 {
    padding: 2rem !important;
}
.socail-icon p {
    padding-top: 20px!important;
    font-size: 18px!important;
    padding-bottom: 0px;
    margin: 0px;
}
.footer-social-media {
    list-style: none;
    padding: 0 0 0 0px;
    margin: 6px 0 0;
}
.service-call ul li {
    width: 100%;
    margin-right: 12px;
    list-style: none;
    /* background: url(../images/widget-link.png) 0% 10px no-repeat; */
    padding-left: 30px;
    /* line-height: 31px; */
    /* color: #000; */
    font-size: 14px;
    color: #000;
    line-height: 1.9em;
    letter-spacing: 1px;
}
.socail-icon ul li {
    padding-left: 5px!important;
    float: left!important;
    width: 20px!important;
    list-style: none!important;
    background: none!important;
}
.footer-social-media li {
    padding: 4px 0;
    display: block;
}
.footer-social-media li a {
    color: #ececec;
    display: block;
    font-size: 12px;
}
.footer-social-media li a .fa {
    color: #fff;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    line-height: 30px;
    text-align: center;
    background: #888;
    /* margin-left: 20px; */
}
li > a:only-child:after {
    content: '';
}
.service-call h2 {
    font-size: 18px;
    padding-bottom: 12px;
    letter-spacing: 1px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
}
p {
    font-size: 14px;
    color: #000;
    line-height: 1.9em;
    letter-spacing: 1px;
    text-align: left;
}
.founder-m .title-w3pvt2 {
    color: #fff;
}
.title-w3pvt2 {
    text-shadow: 3px 1px 3px rgba(45, 45, 45, 0.14);
    font-size: 26px;
    color: #352222;
    font-weight: 700;
    /* text-transform: uppercase; */
    padding-bottom: 15px!important;
}
.mb-5, .my-5 {
    margin-bottom: 3rem !important;
}
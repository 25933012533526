.container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
@media (min-width: 992px){
.col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}}
.fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
}
.text-left {
    text-align: left !important;
}
@media (min-width: 992px){
.p-lg-3 {
    padding: 1rem !important;
}}
@media (min-width: 992px){
.mb-lg-3, .my-lg-3 {
    margin-bottom: 1rem !important;
}}
@media (min-width: 992px){
.mt-lg-3, .my-lg-3 {
    margin-top: 1rem !important;
}}
@media (min-width: 992px){
.col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}}
.baout-r-cantt {
    padding: 5% 0px 0px 5%;
}
h3.title-w3pvt, h3.title-w3pvt.two {
    text-shadow: 3px 1px 3px rgba(45, 45, 45, 0.14);
    font-size: 2.2em;
    color: #000;
    font-weight: 700;
    text-transform: uppercase;
}
.mb-4, .my-4 {
    margin-bottom: 1.5rem !important;
}
.table-pt {
    height: 580px;
    border: none;
    display: flex;
    overflow: auto;
}
tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
}
*, *::before, *::after {
    box-sizing: border-box;
}
tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}
.td tr td {
    font-size: 14px;
    padding: 8px;
    border: #ddd solid 1px;
    color: #000;
    font-weight: 500;
}
*, *::before, *::after {
    box-sizing: border-box;
}
td {
    display: table-cell;
    vertical-align: inherit;
}
.td tr td b {
    font-weight: 600;
}
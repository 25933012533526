.pb-5, .py-5 {
    padding-bottom: 3rem !important;
}
.pt-5, .py-5 {
    padding-top: 3rem !important;
}
@media (min-width: 1200px){
.container {
    max-width: 1140px;
}}
.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
@media (min-width: 992px){
.col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}}
#sidebar .sidebar__inner {
    padding: 10px;
    position: relative;
    transform: translate(0, 0);
    transform: translate3d(0, 0, 0);
    will-change: position, transform;
}
.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem;
}
.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
}
.card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.bg-primary {
    background-color: #484848 !important;
}
.text-white {
    color: #fff !important;
}
.p-0 {
    padding: 0 !important;
}
ul.LeftMenu {
    list-style: outside none;
    margin: 0px;
    padding: 0px;
}
ul.LeftMenu li {
    margin: 0px 0 0px;
    padding: 8px 15px 8px 35px;
    border-bottom: dashed 1px #ccc;
}
li {
    display: list-item;
    text-align: -webkit-match-parent;
}
ul.LeftMenu li:before {
    content: url(https://gkkediaandco.com/wp-content/themes/gkkedia/images/widget-link.png); 
    font-family: 'FontAwesome';
    float: left;
    margin-left: -1.5em;
    margin-top: 3px;
}
ul.LeftMenu li a {
    margin: 0px;
    padding: 0px;
    font-size: 14px;
    color: #000;
}
@media (min-width: 768px){
.col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
}}

.borders {
    border: 1px solid #ddd;
    margin-bottom: 20px;
    padding: 20px;
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.3);
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    background: #fff;
}
@media (min-width: 992px){
.p-lg-3 {
    padding: 1rem !important;
}}
@media (min-width: 992px){
.pl-lg-0, .px-lg-0 {
    padding-left: 0 !important;
}}
@media (min-width: 992px){
.pr-lg-0, .px-lg-0 {
    padding-right: 0 !important;
}}
.mt-5, .my-5 {
    margin-top: 3rem !important;
}
.float-right {
    float: right !important;
}
@media (min-width: 992px){
.col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}}
.img-fluid {
    max-width: 100%;
    height: auto;
}
img {
    vertical-align: middle;
    border-style: none;
}
.float-left {
    float: left !important;
}
@media (min-width: 992px){
.col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}}
@media (min-width: 992px){
.p-lg-3 {
    padding: 1rem !important;
}}
h3.title-w3pvt2 {
    text-shadow: 3px 1px 3px rgba(45, 45, 45, 0.14);
    font-size: 26px;
    color: #000;
    font-weight: 700;
    text-transform: uppercase;
    padding-bottom: 15px!important;
}
.service-scroll {
    position: relative;
    height: 240px;
    margin-bottom: 10px;
}
ul {
    margin: 0;
    padding: 0;
}
.service-call ul li {
    width: 100%;
    margin-right: 12px;
    list-style: none;
    background: url(https://gkkediaandco.com/wp-content/themes/gkkedia/images/widget-link.png) 0% 10px no-repeat;
    padding-left: 30px;
    font-size: 14px;
    color: #000;
    line-height: 1.9em;
    letter-spacing: 1px;
}
.mt-2, .my-2 {
    margin-top: 0.5rem !important;
}

.btn {
    margin-top: 20px;
    border: 2px solid #fff;
    border-radius: 0;
    text-transform: uppercase;
    font-size: .75rem;
    font-weight: 500;
    color: #fff;
    padding: 0.5rem 1.6rem;
    position: relative;
    background: 0 0;
}
.btn-dark {
    height: 60px; 
    background: #333 !important;
    border-color: #080909;
    color: #fff!important;
    padding: 0.7rem 1.4rem!important;
    margin-left: 5px;
}
.btn-dark.btn--leftBorder:before {
    background: #000;
}
.btn--leftBorder:before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -1.25rem;
    min-width: 1.25rem;
    height: 2px;
    background: #fff;
}
.btn-dark a {
    color: #fff!important;
}
.btn-dark.btn--rightBorder:after {
    background: #000;
}
.btn--rightBorder:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -1.25rem;
    min-width: 1.25rem;
    height: 2px;
    background: #fff;
}

element.style {
    overflow-y: hidden;
    outline: none;
}
.service-scroll {
    position: relative;
    height: 240px;
}

.borders {
    border: 1px solid #ddd;
    margin-bottom: 20px;
    padding: 20px;
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.3);
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    background: #fff;
    min-height: 270px;
}
.important2 h4 {
    font-size: 16px;
    padding-top: 21px;
    width: 80%;
    margin: 0 auto;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    letter-spacing: 1px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
}
h4, .h4 {
    font-size: 1.5rem;
}
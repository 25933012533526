/* @media (min-width: 768px){ */
footer {
  background-color: #352222;
  padding: 50px 0;
  color: #fff;
}
@media screen and (max-width: 992px){
.footer-content {
    padding-top: 15px;
}}
.footer-content {
  background-size: cover;
  background-position: center center;
  color: #ffffff;
  /* background: url(../images/footer_bg.jpg); */
}
.hd-cantt {
  padding-right: 16px;
}
.row {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.fa {
  display: inline-block;
  font-size: inherit;
  text-rendering: auto;
}
.footer-title-large {
  font-size: 20px;
  color: white;
  line-height: 30px;
  margin: 0 0 0 15px;
  text-transform: uppercase;
  display: inline-block;
  vertical-align: top;
  text-align: left;
}
.fotter-sms-col {
  position: relative;
  padding-left: 60px;
}
.fotter-sms-col a {
  color: #fff;
  font-size: large;
  text-decoration: none;
}
.pull-right {
  float: right;
}
.footer-line-b {
  border-color: #f5f5f5;
  border-style: dotted;
  border-top-width: 1px;
  margin: 20px 0;
}
.footer-top-inner-janus {
  padding: 2em 0;
}
h3.lavi_title {
  font-size: 1.2em;
  color: #fff;
  font-weight: 600;
}
ul.list-info-lavi {
  list-style: none;
}
ul.list-info-lavi span {
  margin-right: 0.5em;
  color: #fff;
}
.copy-right {
  border-top: 1px solid #656363;
  background: #352222;
}
.container {
  max-width: 1140px;
}
.row {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.paynow {
  position: absolute;
  width: 340px;
  max-width: 100%;
  padding-top: 25px;
}
.copy-right-grids {
  letter-spacing: 0px;
  color: #fff;
  margin: 0px;
  padding: 0px;
  width: 100%;
  float: right;
}
.back-to-tops {
  position: fixed;
  z-index: 1000;
  bottom: -95px;
  right: 0px;
  /* background: #333; */
  width: 141px;
  height: 200px;
  text-align: right;
  margin-right: 10px;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
} 
.back-to-tops img {
  width: 70%;
} 
.back-to-tops img:hover {
  opacity: 0.8;
}
@media screen and (max-width: 992px){
.years {
    padding-top: 10px;
}}
@media screen and (max-width: 600px){
.footer-title-large {
    font-size: 18px;
    line-height: 21px;
    margin: 0 0 0 0px;
    text-align: center;
}}

.footer-title-large {
    font-size: 26px;
    color: #fff;
    line-height: 30px;
    margin: 0 0 0 15px;
    text-transform: uppercase;
    display: inline-block;
    vertical-align: top;
    text-align: left;
}
@media screen and (max-width: 568px){
p, li {
    font-size: 14px!important;
}}
@media screen and (max-width: 568px){
.footer-top-inner-janus {
    padding: 0em 0;
}}

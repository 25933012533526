@media (min-width: 992px){
.services-section-two {
    position: relative;
    overflow: hidden;    
    padding-bottom: 50px;
}}
.container {
    max-width: 1140px;
}
.services-section-two .upper-box {
    position: relative;
    text-align: center;
    border-radius: 15px;
    margin-left: -115px;
    margin-right: -115px;
    padding: 0px 20px 180px;
    /* background-color: #ebebeb; */
}
.services-section-two .inner-container {
    margin-top: -180px;     
}
.sec-title {
    margin-bottom: 56px;
}
.sec-title h2 {
    position: relative;
    color: #141d38;
    font-weight: 700;
    line-height: 1.3em;
    margin-top: 55px;
    font-size: 2.5em;
}
div {
    display: block;
}
.row2 {
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.service-block-two {
    position: relative;
    z-index: 1;
    margin-bottom: 30px;
    margin-right: 20px;
    display: flex;   
    align-items: stretch;
}
.text-center {
    text-align: center !important;
}
.service-block-two .inner-box {
    position: relative;
    overflow: hidden;
    padding: 35px 35px;
    border-radius: 6px;
    background-color: #f5f5f5;
    border: 10px solid pink;
    border-image: repeating-linear-gradient( 45deg, #333, #333 1%, #004161 1%, #004161 8%) 10;
}
.service-block-two .inner-box:hover {
    opacity: 0.8;
}
.service-block-two .inner-box .icon-box {
    position: relative;
    font-size: 65px;
    line-height: 1em;
    color: #0060ff;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;
    display: inline-block;
}
.color {
    display: block;
}
.wh {
    display: none;
}
.service-block-two .inner-box .text {
    position: relative;
    color: #222222;
    z-index: 2;
    font-size: 16px;
    line-height: 1.8em;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;
}
.service-block-two .inner-box::before {
    position: absolute;
    content: '';
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 50%;
    opacity: 0;
    background-color: #004161;
    -webkit-transition: all 600ms ease;
    -ms-transition: all 600ms ease;
    -o-transition: all 600ms ease;
    -moz-transition: all 600ms ease;
    transition: all 600ms ease;
}
.service-block-two .inner-box h5 {
    position: relative;
    z-index: 2;
    color: #222222;
    font-weight: 600;
    line-height: 1.3em;
    margin: 20px 0px 15px;
}
.service-block-two .inner-box h5 a {
    position: relative;
    color: #222222;
    transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    text-transform: uppercase;
    font-size: 18px;
}
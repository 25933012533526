@media (min-width: 768px){
.pb-md-5, .py-md-5 {
    padding-bottom: 3rem !important;
}}
@media (min-width: 768px){
.pt-md-5, .py-md-5 {
    padding-top: 3rem !important;
}}
@media (min-width: 1200px){
.container {
    max-width: 1140px;
}}
.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.row {
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
@media (min-width: 992px){
.col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}}
.rs-left-border-title {
    padding-left: 20px;
    position: relative;
}
.rs-left-border-title h4, .rs-left-border-title h2 {
    text-transform: uppercase;
}
.mb-3, .my-3 {
    margin-bottom: 1rem !important;
}
h1, h2, h3, h4, h5, h6 {
    margin: 0;
    letter-spacing: 1px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
}
h2, .h2 {
    font-size: 2rem;
}
.rs-left-border-title:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 30px;
    background: #0e78b6;
}
.rs-about-lawyer .des {
    margin-bottom: 18px;
    text-align: justify;
}
p {
    font-size: 14px;
    color: #000;
    line-height: 1.9em;
    letter-spacing: 1px;
}
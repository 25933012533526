.gallery-pt {
    padding-top: 40px;
    padding-bottom: 30px;
}
article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
    display: block;
}
@media (min-width: 1200px){
.container {
    max-width: 1140px;
}}
.row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
}
@media (min-width: 992px){
.pl-lg-0, .px-lg-0 {
    padding-left: 0 !important;
}}

@media (min-width: 992px){
.pr-lg-0, .px-lg-0 {
    padding-right: 0 !important;
}}
@media (min-width: 992px){
.col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}}
@media (min-width: 768px){
.col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}}
/* @media (min-width: 768px){ */
.col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}

.youtube {
    background: #bb0000;
    color: white;
}
.gallery-container figure {
    overflow: hidden;
    position: relative;
}
figure {
    margin: 0 0 1rem;
}
.img-fluid {
    max-width: 100%;
    height: auto;
}
img {
    vertical-align: middle;
    border-style: none;
}
.video-preview a figcaption {
    position: absolute;
    bottom: 0;
    padding: 10px 15px;
    background: rgba(0,0,0,.8);
    color: #fff;
    right: 0;
    left: 0;
}
.text-center {
    text-align: center !important;
}
div {
    display: block;
}
h3.title-w3pvt, h3.title-w3pvt.two {
    text-shadow: 3px 1px 3px rgb(45 45 45 / 14%);
    font-size: 2.2em;
    color: #000;
    font-weight: 700;
    text-transform: uppercase;
}
.pb-3, .py-3 {
    padding-bottom: 1rem !important;
}
.explore-button a {
    position: relative;
    color: #333;
    text-transform: uppercase;
}
.explore-button a:hover {
    opacity: 0.6;
}
.explore-button a:before {
    content: '';
    position: absolute;
    top: 11px;
    height: 2px;
    width: 15px;
    background: #333;
    left: -25px;
}
.explore-button a:after {
    content: '';
    position: absolute;
    top: 11px;
    height: 2px;
    width: 15px;
    background: #333;
    right: -25px;
}
.ms-theme-cubic.ms-loaded {
    opacity: 1;
}
.ms-theme-cubic {
    /* opacity: 0; */
    padding-bottom: 40px;
    /* -webkit-transition: opacity .3s; */
    /* transition: opacity .3s; */
}
.metaslider.ms-theme-cubic *, .metaslider.ms-theme-cubic *::before, .metaslider.ms-theme-cubic *::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.metaslider.ms-theme-cubic .flexslider {
    margin-bottom: 0;
}

element.style {
    display: block;
    width: 100%;
    float: left;
    margin-right: -100%;
    position: relative;
    opacity: 1;
    transition: opacity 0.6s ease 0s;
    z-index: 2;
}
.metaslider.ms-theme-cubic .flexslider ol.flex-control-nav:not(.flex-control-thumbs), .metaslider.ms-theme-cubic ul.rslides_tabs, .metaslider.ms-theme-cubic div.slider-wrapper .nivo-controlNav:not(.nivo-thumbs-enabled) {
    background: transparent;
    bottom: 15px;
    -webkit-box-pack: center;
    display: flex;
    -ms-flex-pack: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center;
    justify-content: center;
    line-height: 15px;
    margin: 0;
    padding: 0 !important;
    position: absolute;
    -webkit-transition: top .2s;
    transition: top .2s;
    z-index: 8;
}

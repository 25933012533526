@media (min-width: 1200px){
.container {
    max-width: 1140px;
}}
.pt-100 {
    padding: 70px 0px!important;
}
.pbb {
    padding-bottom: 25px;
}
@media (min-width: 768px){
.col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}}
.important {
    background: #fff;
    padding: 15px;
    min-height: 230px;
}
.borders {
    border: 1px solid #ddd;
    margin-bottom: 20px;
    padding: 20px;
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.3);
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    background: #fff;
}
body a {
    text-decoration: none;
    transition: 0.5s all;
    -webkit-transition: 0.5s all;
    -moz-transition: 0.5s all;
    -o-transition: 0.5s all;
    -ms-transition: 0.5s all;
}
a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}
.important h4 {
    font-size: 16px;
    padding: 20px 0px;
}
h1, h2, h3, h4, h5, h6 {
    margin: 0;
    letter-spacing: 1px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
}
.blog-btn {
    background: #1a8ed4;
    padding: 5px 16px;
    font-size: 14px;
    color: #fff;
    display: inline-block;
    margin-top: 10px;
}
header {
    position: relative;
    z-index: 9;
    width: 100%;
    padding: 1rem 0 1rem 0;
   background-color: #fdfbfbf1
}
.container-2 {
    width: 1366px;
    max-width: 100%;
    margin: 0px auto;
}
.align-items-center {
    -webkit-box-align: center !important;
    align-items: center !important;
}
.justify-content-between {
    -webkit-box-pack: justify !important;
    justify-content: space-between !important;
}
.d-lg-flex {
    display: flex !important;
}
a.navbar-brand {
    font-size: 0.8em;
    color: #fff;
    padding: 0;
    font-weight: 700;
    margin: 0;
    letter-spacing: 1px;
    text-shadow: 0 1px 2px rgb(0 0 0 / 37%);
    position: relative;
    text-transform: uppercase;
}
.navbar-brand {
    display: inline-block;
    line-height: inherit;
    white-space: nowrap;
}
img {
    vertical-align: middle;
    border-style: none;
}
.mt-lg-0{
    margin-top: 0 !important;
}
.mt-1 {
    margin-top: 0.25rem !important;
}
nav ul {
    float: right;
    padding: 0;
    margin: 0;
    list-style: none;
    position: relative;
}
nav ul li {
    margin: 0px 4px;
    display: inline-block;
}
nav a {
    font-weight: 400;
    text-transform: uppercase;
    color: #000;
    letter-spacing: 1px;
    font-size: 14px;
    position: relative;
    padding: 0px 2px;
}
nav ul ul {
    display: none;
    position: absolute;
    top: 25px;
    background: #fff;
    padding-top: 10px;
    width: 400px;
}
nav ul ul li {
    border-bottom: 1px dotted #ccc;
    padding: 4px 0px;
    float: none;
    display: list-item;
    position: relative;
}
nav ul ul li a {
    color: #000;
    padding: 5px 10px;
    display: block;
}
.dropdown {
    position: relative;
    display: inline-block;
  } 
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #fff;
    min-width: 300px;
    height: auto;
    max-height: 600px;
    overflow-x: hidden;
    text-align: left;
    border-bottom: 1px dotted #ccc;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
.dropdown-content a {
    color: black;
    padding: 12px 16px;
    border-bottom: 1px dotted #ccc;    display: block;
  }
  
  .dropdown-content a:hover {background-color: #ddd; color:darkslateblue; text-decoration: none;font-weight: 600; }
  
  .dropdown:hover .dropdown-content {display: block; text-decoration: dashed;}
